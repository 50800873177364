<template>
  <workbenchbox>
    <mySign></mySign>
  </workbenchbox>
</template>
<script setup>
import { reactive, ref, unref } from "vue";
import workbenchbox from "@/components/layout/workbenchbox.vue";
import mySign from "@/views/user/components/userModule/mySign.vue"
const state = reactive({
});
</script>
<style lang="scss" scoped >
::v-deep .workbenchbox-content{
  background-color: #fff;
}
// @import "@/styles/general/element.scss"; //element 样式重置
// @import "@/styles/general/element/table.scss"; //element 列表样式重置
// @import "@/styles/general/element/pagination1.scss"; //element 分页样式重置
</style>